<template>
    <section class="register-page section-b-space">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h3>{{title}}</h3>
                    <div class="theme-card">
                        <form-wizard :title="title" subtitle="" shape="tab">
                            <tab-content title="Informations Personnelles" icon="ti-user"
                                         :before-change="()=>validateStep('step1')"
                            >
                                <step1 :godSon="godFather" ref="step1" @on-validate="mergePartialModels"></step1>
                            </tab-content>
                            <tab-content title="Supplementaire" icon="ti-check">
                                <step2 :godSon="godFather" ref="step2"></step2>
                            </tab-content>
                            <template slot="footer" slot-scope="props">
                                <div class="wizard-footer-left">
                                    <button v-if="props.activeTabIndex > 0 "
                                            @click="props.prevTab()" class="wizard-footer-right btn btn-solid mt-2">
                                        Precedent
                                    </button>
                                </div>
                                <div class="wizard-footer-right">
                                    <button v-if="!props.isLastStep" @click="props.nextTab()"
                                            class="wizard-footer-right btn btn-solid mt-2">Suivant
                                    </button>

                                    <button v-else @click="saveCustomer(godFather)"
                                            class="wizard-footer-right finish-button btn btn-solid mt-2">
                                        {{props.isLastStep ? 'Envoyer' : 'Suivant'}}
                                    </button>
                                </div>
                            </template>
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import customerMixin from "../../../../mixins/customerMixins";



    export default {
        name: "register-godson",

        mixins: [customerMixin],

        data(){
            return {
                title: 'Créer un Compte',
                godFather: true
            }
        }
    }
</script>

<style scoped>

</style>
